<template>
  <div
    style="background-color: #ffeddd; border-top: 1px solid #ffc27a"
    class="py-6"
    v-if="items[0]"
  >
    <v-container>
      <v-row justify="center" class="pb-4">
        <v-col>
          <div
            class="headingParagraph"
            style="font-family: Roboto Slab; display: flex"
          >
            <div>Schools participating in this Fundraiser</div>
            <div class="titleLine"></div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-col class="px-2" cols="6" sm="3" v-for="(item, i) in items" :key="i">
          <v-card class="card">
            <v-card-title class="py-2">
              <v-row justify="center">
                <v-col cols="10">
                  <div style="width: 100%; margin: auto">
                    <img width="100%" :src="item.school_logo" alt="" />
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="px-0">
              <div class="schoolTitleDiv py-2">
                <v-row justify="center">
                  <v-col cols="10" class="text-center">
                    <p
                      style="
                        color: #000000;
                        font-family: Roboto Slab;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 161.5%;
                        margin-bottom: 0px;
                        /* or 39px */
                      "
                    >
                      {{ item.school_name }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions class="pb-7">
              <v-row justify="center">
                <v-col cols="12" class="px-4 text-center">
                  <p
                    style="color: #067605; font-size: 23px; margin-bottom: 0px"
                  >
                    ${{
                      item.orignal_total_fund_raised_50.toLocaleString("en-US")
                    }}
                    <span style="color: #757575; font-size: 16px">
                      raised of
                      {{ item.school_goal_50.toLocaleString("en-US") }}</span
                    >
                  </p>
                </v-col>
                <v-col cols="11" class="px-4 py-0">
                  <v-progress-linear
                    :value="item.fund_raise_percentage"
                    color="#4A3290"
                    height="8"
                    style="border-radius: 20px"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="12" class="text-center px-6">
                  <v-btn
                    text
                    style="border: 1px solid #38227a; border-radius: 10px"
                    class="text-capitalize"
                    width="100%"
                    @click="viewPublicDonationPageHandler(item)"
                  >
                    <span style="color: #38227a; font-size: 20px"
                      >view more</span
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet
      class="mx-auto"
      max-width="100%"
      color="#ffeddd"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-slide-group multiple>
        <v-slide-item v-for="(item, i) in items" :key="i" class="ma-3">
          <v-card class="card" max-width="280px">
            <v-card-title class="py-2">
              <v-row justify="center">
                <v-col cols="8">
                  <div style="width: 100%; margin: auto">
                    <img width="100%" :src="item.school_logo" alt="" />
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="px-0">
              <div class="schoolTitleDiv py-2">
                <v-row justify="center">
                  <v-col cols="8" class="text-center">
                    <p
                      style="
                        color: #000000;
                        font-family: Roboto Slab;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 161.5%;
                        margin-bottom: 0px;
                        /* or 39px */
                      "
                    >
                      {{ item.school_name }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions class="pb-7">
              <v-row justify="center">
                <v-col cols="10" class="px-4 text-center">
                  <p
                    style="color: #067605; font-size: 23px; margin-bottom: 0px"
                  >
                    ${{
                      item.orignal_total_fund_raised_50.toLocaleString("en-US")
                    }}
                    <span style="color: #757575; font-size: 16px">
                      raised of
                      {{ item.school_goal_50.toLocaleString("en-US") }}</span
                    >
                  </p>
                </v-col>
                <v-col cols="9" class="px-2 py-0">
                  <v-progress-linear
                    :value="item.fund_raise_percentage"
                    color="#4A3290"
                    height="8"
                    style="border-radius: 20px"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="10" class="text-center px-4">
                  <v-btn
                    text
                    style="border: 1px solid #38227a; border-radius: 10px"
                    class="text-capitalize"
                    width="100%"
                    @click="viewPublicDonationPageHandler(item)"
                  >
                    <span style="color: #38227a; font-size: 20px"
                      >view more</span
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Helper from "@/helper";
export default {
  name: "SchoolDetail",
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    power() {
      return (12000 / 12450) * 100;
    },
  },
  watch: {},
  methods: {
    getSchoolList() {
      this.items = this.getCampaignDetail.school_list;
      // console.log(this.items, "school list");
    },
    viewPublicDonationPageHandler(item) {
      const queryParam = "school=" + item.id;
      Helper.redirectToPublicDonationPage("", queryParam);
    },
  },
  mounted() {
    this.getSchoolList();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.schoolTitleDiv {
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px dashed #c6c6c6;
  width: 100%;
}
.card.v-card {
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}
.titleLine {
  height: 4px;
  width: 50px;
  background-color: #2c1963;
  margin-left: 10px;
  margin-top: 18px;
  border-radius: 20px;
}
.headingParagraph {
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
@media only screen and (max-width: 600px) {
  .titleLine {
    height: 3px;
    width: 30px;
    float: right;
  }
  .headingParagraph {
    font-size: 20px;
  }
  .card.v-card {
    max-width: 350px;
  }
}
</style>
